import React, { useEffect, useState } from "react";
import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import introJs from 'intro.js';
import 'intro.js/introjs.css';


export default function HomePage({ title , isAppReady, appDisclaimer , pageTitle, ExportChartFootnote, menuItemsStrings}) {

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    document.title = `${pageTitle} - ${title}`;
  }, [pageTitle,title]);
  console.log(process.env.REACT_APP_PUBLIC_URL);

  const addClassToElement = () => {
    const qlikElement = document.querySelector('li[data-csid="Статус проєкту"]');
    if (qlikElement) {
      qlikElement.classList.add('tour-global-current-selections-single');
      return true; // Return true if the element is found and the class is added
    }
    return false; // Return false if the element is not found
  };

  const startTour = () => {
    const addClassAndStartTour = () => {
      if (addClassToElement()) {
    const tour = introJs();

    let options = {
      steps: [
        {
          element: '.tour-homepage-page',
          intro: menuItemsStrings.tourHomePage,
        },
        {
          element: '.tour-global-sidebar',
          intro: menuItemsStrings.tourGlobalNavbar,
        },
        {
          element: '.tour-home-kpi-row',
          intro: menuItemsStrings.tourHomeKPIRow,
          tooltipClass: 'custom-tooltip-tour-home-kpi-row', 
        },
        {
          element: '.tour-home-kpi-financial-coverage',
          intro: menuItemsStrings.tourHomeKPISingle,
          tooltipClass: 'custom-tooltip-tour-home-kpi-financial-coverage',
        },
        {
          element: '.tour-global-current-selections',
          intro: menuItemsStrings.tourGlobalCurrentSelections,
        },
        {
          element: '.tour-global-current-selections-single',
          intro: menuItemsStrings.tourGlobalCurrentSelectionsDefaultStatus,
        },
        {
          element: '.tour-home-filterpane-side',
          intro: menuItemsStrings.tourHomeFiltersSidebar,
        },
        {
          element: '.tour-global-filterpane-dropdown',
          intro: menuItemsStrings.tourGlobalAllFilters,
        },
        {
          element: '.tour-home-barchart',
          intro: menuItemsStrings.tourHomeBarchart,
        },
        {
          element: '.tour-home-barchart-kpi-selection',
          intro: menuItemsStrings.tourHomeBarchartSelection,
          keyboardNavigation: false,
          tooltipClass: 'custom-tooltip-tour-home-barchart-kpi-selection', // Add custom class
        },
      ],
      showBullets: true,
      showStepNumbers: true,
      nextLabel: menuItemsStrings.tourParamsNext,
      prevLabel: menuItemsStrings.tourParamsPrev,
      doneLabel: menuItemsStrings.tourParamsDone,
      stepNumbersOfLabel: menuItemsStrings.tourParamsOf,
      //skipLabel: menuItemsStrings.tourParamsSkip,
      helperElementPadding: 0,
      dontShowAgain: false,
      autoPosition: true,
      scrollToElement: true
    };

      // Apply options and start the tour
      tour.setOptions(options);

    tour.oncomplete(() => {
      localStorage.setItem('hasCompletedTour', 'true');
    });

    tour.onexit(() => {
      localStorage.setItem('hasCompletedTour', 'true');
    });

    tour.start();
  } else {
    // Retry if the element is not ready
    setTimeout(addClassAndStartTour, 100); // Retry after 100ms
  }
};

addClassAndStartTour();
};

  useEffect(() => {
    const initializeTour = () => {

      console.log("Current Location:", window.location);

      const hasCompletedTour = localStorage.getItem('hasCompletedTour');
      // const isMobile = window.innerWidth < 1200; // Check screen width
      const mediaQuery = window.matchMedia("(min-width: 1200px)"); // Match the media query

    // Additional conditions for starting the tour
    const isCorrectHref = window.location.href === process.env.REACT_APP_API_TOUR + "/#/";
    const isEmptySearch = window.location.search === "";

    console.log("isAppReady:", isAppReady);
    console.log("hasCompletedTour:", hasCompletedTour);

      if (!hasCompletedTour && isAppReady && mediaQuery.matches && isCorrectHref && isEmptySearch) {
      // Wait until the element is ready and class is added
        const checkAndStartTour = () => {
          if (addClassToElement()) {
            startTour(); // Start the tour if the class is added
          } else {
            // Retry until the element becomes available
            setTimeout(checkAndStartTour, 100); // Retry after 100ms
          }
        };

        checkAndStartTour();
      }
    };

    initializeTour();
  }, [isAppReady]); // Ensure it triggers when `isAppReady` changes

  return (
    <section className="content tour-homepage-page">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            <h6 className="page-disclaimer"> {appDisclaimer} </h6>
            {/* <QlikObject
              className="page-title"
              id="ANJWBAj"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          <div className="col-md-2 col-sm-3 col-2 d-flex justify-content-end">
            <button 
              onClick={startTour} 
              className="start-tour-button"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isHovered ? (
                <>
                  {menuItemsStrings.tourParamsTourStartButton} {/* Text */}
                  <FontAwesomeIcon icon="circle-play" className="ml-2" /> {/* Icon with spacing */}
                </>
              ) : (
                <FontAwesomeIcon icon="circle-play" /> /* Icon only when not hovered */
              )}
            </button>
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          {/* <div className="col-md-2 col-sm-3 col-2">
            <QlikObject
              id="MDkpaL"
              height="30px"
              showLoader={false}
              showOverlayer={false}
            />
          </div> */}
        </div>
      </div>
          
      {/* <div className="row justify-content-start">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="a49c567e-5174-4fbc-8463-a0e7a6a4ce20"
              height="30px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="NYxkAp"
              height="40px"
              showLoader={false}
            />
          </div>
      </div> */}

      <div className="container-fluid">



        <div className="row justify-content-start">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">

              <div className="row justify-content-left tour-home-kpi-row">
                {/* KPIs Row */}

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: number of projects */}
                      <QlikCard
                        id="PGppnd"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 tour-home-kpi-financial-coverage">
                      {/* KPI: Overall estimated budget */}
                      <QlikCard
                        id="tbCaXJP"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: Confirmed funding */}
                      <QlikCard
                        id="epRmEQA"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: Financial coverage */}
                      <QlikCard
                        id="tjJjzu" //tjJjzu
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>

              </div>


              <div className="row justify-content-left">
                {/* Charts Row */}

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                  {/* barchart column */}
                  <div className="row justify-content-center tour-home-barchart ">
                    <div className="col-12">
                      {/* barchart */}
                      <QlikCard
                        id="AvjgfP"
                        // height="352px"
                        height="max(calc(100vh - 263px - 90px - 36px), 150px)"
                        showHeader={true}
                        showMaximize={false}
                        showExport={true}
                        showPdfExport
                        showImageExport={true}
                        exportImageWidth={1280}
                        exportImageHeight={720}
                        onBeforeExportHandler={async ({ vis, exportType }) => {
                          //console.log(data);
                          console.log(vis);
                          if(exportType in { pdf: true, image: true} && vis)
                            await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                        }}
                        onAfterExportHandler={async ({ vis, exportType }) => {
                          console.log('Export type: ', exportType);
                          if(exportType in { pdf: true, image: true})
                            vis.setOptions({ showTitles: false, footnote:"" });
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    {/* indicator selection for barchart */}
                      <div className="col-12 col-xl-6 tour-home-barchart-kpi-selection">
                          <QlikObject
                            id="qsPECB"
                            height="32px"
                            showHeader={false}
                            showMaximize={false}
                            showExport={false}
                          />
                      </div>
                  </div>

                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7">
                  {/* container column */}
                  <div className="row justify-content-center ">
                    {/* container tab selection */}
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      {/* container tab: map */}
                      <QlikCard
                        id="CkBs"
                        height="48px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      {/* container tab: dynamics */}
                      <QlikCard
                        id="bWjpKV"
                        height="48px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      {/* container tab: treemap */}
                      <QlikCard
                        id="ALLx"
                        height="48px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* chart container */}
                      <QlikCard
                        id="avVcA"
                        // height="294px"
                        height="max(calc(100vh - 263px - 90px - 50px - 36px), 150px)"
                        showHeader={true}
                        showMaximize={false}
                        showExport={menuItemsStrings.containerTabSelector != '1'}
                        showPdfExport
                        showImageExport={true}
                        exportImageWidth={1280}
                        exportImageHeight={720}
                        onBeforeExportHandler={async ({ vis, exportType }) => {
                          //console.log(data);
                          console.log(vis);
                          // if(exportType in { pdf: true, image: true} && vis)
                          //   await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                        }}
                        onAfterExportHandler={async ({ vis, exportType }) => {
                          console.log('Export type: ', exportType);
                          if(exportType in { pdf: true, image: true})
                            vis.setOptions({ showTitles: false, footnote:"" });
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-12 col-xl-6">
                        {/* indicator selection for chart container */}
                        <QlikObject
                          id="cZWRP"
                          height="32px"
                          showHeader={false}
                          showMaximize={false}
                          showExport={false}
                        />
                      </div>
                  </div>

                  

                </div>

              </div>

            </div>


            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 tour-home-filterpane-side">
                  {/* FilterPane */}
                  <QlikCard
                    id="GhVZpW"
                    // height="485px"
                    height="max(calc(100vh - 263px - 25px), 100px)"
                    showHeader={false}
                    showMaximize={false}
                    showExport={false}
                    showOverlayer={false}
                  />
            </div>



          </div>

          <div className="row">
            <div className="col-12">
              <QlikObject
                id="AQtTDP"
                height="0px"
                showLoader={false}
                showOverlayer={false}
              />
            </div>
          </div>
          
        </div>
    </section>
  );
}
